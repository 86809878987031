import React from "react"
import { graphql } from 'gatsby'
import { Container } from "components/library"
import { lazily } from "react-lazily";


const { CtaBlock } = lazily(() => import("components/common/cta-block"));
const { WhyPolymerize } = lazily(() => import("components/common/why-polymerize/why-polymerize"));
const { Faqs } = lazily(() => import("components/common/faqs"));
const { AcceleratingResearch } = lazily(() => import("components/pages/home/accelerating-research/home-accelerating-research"));
const { UseCases } = lazily(() => import("components/pages/home/use-cases/home-use-cases"));
const { Hero } = lazily(() => import("components/pages/home/hero/home-hero"));
const { VideoBlock } = lazily(() => import("components/pages/home/video-block/home-video-block"));
const { DesignedBy } = lazily(() => import("components/pages/home/designed-by/home-designed-by"));


const IndexPage = ({ data: { allFaq: { nodes } } }: any) => {
  return (
    <main className="-mt-28 sm:-mt-36">
      <div className="flex flex-col gap-20 relative pb-12 sm:pb-16 pt-40 sm:pt-60 bg-hero-bg bg-no-repeat bg-cover">
        <Hero />
        <VideoBlock />
      </div>
      <AcceleratingResearch />
      <UseCases />
      <DesignedBy />
      <WhyPolymerize />
      <Container>
        <Faqs faqs={nodes} />
      </Container>
      <CtaBlock />
      {/* <ConnectEarlyBirdPopup /> */}
      {/* <WebinarPopup /> */}
    </main>
  )
}

export default IndexPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allFaq(filter: {categories: {elemMatch: {name: {eq: "Website Home"}}}}) {
      nodes {
        id
        question
        answer
      }
    }
  }
`
